<template>
  <v-container fluid>
    <v-card class="pa-4">
      <v-row>
        <v-col>
          <Busqueda />
        </v-col>
        <v-col cols="auto" align-self="center">
          <v-btn-toggle dense borderless>
            <v-btn icon @click="changeMode('grid')">
              <v-icon
                >mdi-view-grid{{ mode == "grid" ? "" : "-outline" }}</v-icon
              >
            </v-btn>
            <v-btn icon @click="changeMode('list')">
              <v-icon>{{
                mode == "list"
                  ? "mdi-format-list-bulleted-type"
                  : "mdi-format-list-checkbox"
              }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col cols="12" class="col-md-10">
        <div v-if="!loading" class="d-flex flex-wrap justify-space-around">
          <Lugar
            v-for="l in lugares"
            :key="Number(l.id)"
            :id="Number(l.id_old)"
            :nombre="l.nombre"
            :cp="Number(l.cp)"
            :img_src="l.imagen_defecto"
            :class="`align-self-stretch ${mode}`"
          />
        </div>
        <template v-else>
          <v-row no-gutters justify="center" class="my-10">
            <v-progress-circular
              indeterminate
              size="80"
              width="8"
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-pagination
          total-visible="10"
          v-model="page"
          :length="nPages"
          color="primary"
        >
        </v-pagination>
      </v-col>
      <v-col cols="12" class="col-md-2">
        <Anuncio />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Lugar from "@/components/Lugar.vue";
import Busqueda from "@/components/Busqueda.vue";
import Anuncio from "@/components/Anuncio.vue";

import axios from "redaxios";

export default {
  components: {
    Lugar,
    Busqueda,
    Anuncio,
  },
  data() {
    return {
      lugares: [],
      mode: this.readCookie('mode') || 'grid',
      page: Number(this.$route.query.pagina) || 1,
      nPages: null,
      loading: true,
    };
  },
  methods: {
    search(page) {
      this.loading = true;
      const LUGARESPORPAGINA = 24;
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/lugares.php`,
        params: {
          busqueda: this.$route.query.busqueda,
          pagina: page,
        },
      })
        .then((res) => {
          this.lugares = res.data.resultados;
          this.nPages = Math.ceil(res.data.total / LUGARESPORPAGINA);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.loading = false));
    },
    changeMode(mode) {
      this.mode = mode;
      if (this.readCookie('cookiesConfirm')) {
        document.cookie = `mode=${mode}; path=/`;
      }
    },
    readCookie(name) {
      var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
      return v ? v[2] : null;
    },
  },
  mounted() {
    this.search(this.$route.query.pagina || 1);
  },
  watch: {
    page(n) {
      // this.search(n);
      this.$router.push({
        name: "Busqueda",
        query: { busqueda: this.$route.query.busqueda, pagina: n },
      });
    },
  },
};
</script>

<style>
</style>